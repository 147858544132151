<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>SALES PERFORMANCE</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Evaluation' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <P>Rep = Billy Krohne</P>
        </div>
      </v-col>
    </v-row>
    <datatable
      :headers="closings_table_data.headers"
      :staticData="closings_table_data.data"
      :componentURL="componentURL"
      :search="false"
    ></datatable>

    <datatable
      :headers="initial_bid_table_data.headers"
      :staticData="initial_bid_table_data.data"
      :componentURL="componentURL"
      :search="false"
    ></datatable>

    <datatable
      :headers="close_rate_table_data.headers"
      :staticData="close_rate_table_data.data"
      :componentURL="componentURL"
      :search="false"
    ></datatable>

    <datatable
      :headers="rev_table_data.headers"
      :staticData="rev_table_data.data"
      :componentURL="componentURL"
      :search="false"
    ></datatable>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";

export default {
  components: { Datatable },

  data() {
    return {
      componentURL: "",
      closings_table_data: {
        headers: [
          { text: "CLOSINGS", value: "closing" },
          { text: "ACTUAL", value: "actual" },
          { text: "GOAL", value: "goal" },
          { text: "%", value: "percentage" },
        ],
        data: [
          {
            closing: "1-Day",
          },
          {
            closing: "7-Day",
          },
          {
            closing: "30-Day",
          },
          {
            closing: "90-Day",
          },
          {
            closing: "6 months",
          },
        ],
      },
      initial_bid_table_data: {
        headers: [
          { text: "INITIAL BID $$", value: "closing" },
          { text: "ACTUAL", value: "actual" },
          { text: "GOAL", value: "goal" },
          { text: "%", value: "percentage" },
        ],
        data: [
          {
            closing: "1-Day",
          },
          {
            closing: "7-Day",
          },
          {
            closing: "30-Day",
          },
          {
            closing: "90-Day",
          },
          {
            closing: "6 months",
          },
        ],
      },
      close_rate_table_data: {
        headers: [
          { text: "CLOSE RATE", value: "closing" },
          { text: "ACTUAL", value: "actual" },
          { text: "GOAL", value: "goal" },
          { text: "%", value: "percentage" },
        ],
        data: [
          {
            closing: "1-Day",
          },
          {
            closing: "7-Day",
          },
          {
            closing: "30-Day",
          },
          {
            closing: "90-Day",
          },
          {
            closing: "6 months",
          },
        ],
      },
      rev_table_data: {
        headers: [
          { text: "REV/EVAL", value: "closing" },
          { text: "ACTUAL", value: "actual" },
          { text: "GOAL", value: "goal" },
          { text: "%", value: "percentage" },
        ],
        data: [
          {
            closing: "1-Day",
          },
          {
            closing: "7-Day",
          },
          {
            closing: "30-Day",
          },
          {
            closing: "90-Day",
          },
          {
            closing: "6 months",
          },
        ],
      },
    };
  },
};
</script>

<style></style>
